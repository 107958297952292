
import SignupPassword from '@/components/shared/SignupPassword.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import PageAuth from '@/components/layout/PageAuth.vue'

import { Form as Validation } from 'vee-validate'

import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
import useEditEmailModal from '@/compositions/editEmailModal'

export default defineComponent({
  name: 'SignupCredentials',
  components: {
    SignupPassword,
    TmButton,
    TmFormLine,
    Validation,
    PageAuth,
  },
  setup() {
    const route = useRoute()
    const signup = ref({
      firstName: '',
      lastName: '',
      email: route.params.email || 'test@test.com',
      password: '',
      country: {
        name: 'United States',
        id: 'us',
      },
    })

    const editEmailModal = useEditEmailModal(signup.value.email)

    return {
      editEmailModal,
      signup,
    }
  },
})
